import clientAPI from "./apiclient.config";
import {environment} from "@/config";
import qs from 'qs';
const baseURL = window.location.protocol + '//' + window.location.host+environment.VUE_APP_ORCH_API
export default {

  init(token_id,transactionId) {
    console.log("auth.service init");

    const url = baseURL + "transaction/"+transactionId+"/init"
    console.log("auth.service url: " + url);

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token_id,
        'Content-Type': 'application/x-www-form-urlencoded',
        'InfoCert-Correlation-ID': transactionId
      }
    }
    let result = clientAPI().post(url,{} ,config);
    console.log("auth.service result: " + result);

    return result;
  },
  next(token_id, transactionId) {
    console.log("auth.service next");

    const url = baseURL + "transaction/"+transactionId+"/next"
    console.log("auth.service url: " + url);

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token_id,
        'Content-Type': 'application/x-www-form-urlencoded',
        'InfoCert-Correlation-ID': transactionId
      }
    }
    let result = clientAPI().post(url,{} ,config);
    console.log("auth.service result: " + result);
    return result;
  },
  abort(token_id, transactionId) {
    console.log("auth.service abort");

    const url = baseURL + "transaction/"+transactionId+"/abort"
    console.log("auth.service url: " + url);
    let config = {
      headers: {
        'Authorization': 'Bearer ' + token_id,
        'Content-Type': 'application/x-www-form-urlencoded',
        'InfoCert-Correlation-ID': transactionId
      }
    }
    let result = clientAPI().post(url,{} ,config);
    console.log("auth.service result: " + result);
    return result;
  }
};
