
function _destructuringDomain() {
	var parts = location.hostname.split('.');
	var subdomain = location.hostname.split('.').shift();
	var upperleveldomain = '.' + location.hostname.substring(location.hostname.lastIndexOf(".",  location.hostname.lastIndexOf(".") - 1) + 1);

	return {
		parts,
		subdomain,
		upperleveldomain
	}
}

/**
 * @param name {string}
 * @param value {string}
 * @param days {string}
 * */
export function createCookie(name, value, days = 0){
	let expires = "";

	let date = new Date();
	date.setTime(date.getTime() + (days*24*60*60*1000));
	expires = "; expires=" + date.toUTCString();

	if(days){
		document.cookie =
			name + "=" +
			(value || "")  +
			expires +
			';' + 'domain=' + _destructuringDomain().upperleveldomain +
			";path=/";
	} else {
		document.cookie =
			name + "=" +
			(value || "")  +
			';' + 'domain=' + _destructuringDomain().upperleveldomain +
			";path=/";
	}
}

export function createCookieWithTabId(name, value, id, days = 0){
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();

    if(id){
        value = { [id]: value }
        value = JSON.stringify(value);
    }

    if(!days){
        document.cookie =
            name + "=" +
            (value || "")  +
            ';' + 'domain=' + _destructuringDomain().upperleveldomain +
            ";path=/";

        return;
    }

    document.cookie =
        name + "=" +
        (value || "")  +
        expires +
        ';' + 'domain=' + _destructuringDomain().upperleveldomain +
        ";path=/";
}

/**
 * @param name {string}
 * */
export function getCookie(name){
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getCookieFromTabId(name, id){
    let cookieString = document.cookie;
    let cookies = cookieString.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].split('=');
        if (cookie[0] === name) {
            return JSON.parse(decodeURIComponent(cookie[1]))[id];
        }
    }
    return "";
}

/**
 * @param name
 * @param value
 * @param days
 * */
export function updateCookie(name, value, days){
	if (days) {
		var expires = "";
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}

	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
 * @param name {string}
 * */
export function deleteCookie(name){
	document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
