import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from 'vue-i18n'


import idsrvAuth from './idsrvAuth'
//Import translation file
import it from './locales/it.json'
import en from './locales/en.json'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Consent from 'spid-wc-consent-infocert/dist/consent.common.mjs';
//import Consent from 'spid-wc-consent-infocert/dist/consent.common.mjs';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Parent from 'spid-wc-parent-infocert/dist/parent.common.mjs';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Mfa} from 'spid-mfa-component/dist/mfa.common.mjs';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SmsAuth from "spid-wc-sms-authenticator/dist/smsauth.common.mjs";

function getLang() {  /// Retrieve cookie function
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const param = params.get("lang")
    if (param)
        return param;
    else return 'it';
}
const i18n = createI18n({
    legacy: false,
    messages: {
        it: it,
        en: en
    },
    locale: getLang(),
    fallbackLocale:'it'
});

idsrvAuth.startup().then(ok => {
    if (ok) {
        const mountEl = document.querySelector("#app");
        const app = createApp(App,{ ...(mountEl as HTMLElement).dataset} )
            .use(Consent)
            .use(Parent)
            .use(router)
            .use(i18n)
            .use(SmsAuth)
            //.use(SmsAuth)

        app.component('mfa', Mfa);

        // a little something extra
        app.config.globalProperties.$oidc = idsrvAuth

        app.mount('#app')
    } else {
        console.log('Startup was not ok')
    }
}).catch((err) => {
    console.log("error:" , err);
});
