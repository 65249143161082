let config = null;
const timestamp = new Date().getTime();
const configUrl = window.location.origin + '/config/config.json?version=' + timestamp;
const request = new XMLHttpRequest();
request.open('GET', configUrl, false);
request.send(null);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
config = JSON.parse(request.responseText);

export const environment = {
  ...config
}
