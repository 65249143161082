<template>
  <!-- SPID HEADER * begin * -->
  <header v-if="agid" class="ic1-header-wrapper">
    <div class="ic1-header">
      <div class="ic1-header-info">
        <img src="/img/logo-infocert-positivo-no-border.svg" alt="Infocert ID">
        <div class="ic1-divider"></div>
        <h1 class="ic1-header-title">{{ APP_NAME }}</h1>
      </div>
    </div>
  </header>

  <!-- SPID HEADER * begin * -->
  <div id="header" v-else>
    <div id="header-inner">
      <img class="logo_spid" :src="logo_fs" alt="Logo_SPID" v-if="!agid"/>
      <img class="logo_tim" :src="logo_sn" alt="Logo_TIM" v-if="!agid"/>
      <img class="logo_trust" :src="logo_td" alt="Logo_TRUST" v-if="!agid"/>
    </div>
  </div>

  <div v-if="agid" class="ic1-container ic1-bg-primary">

    <div class="ic1-row">
      <div class="ic1-col-md-12 ic1-col-12 ic1-centered"><img class="img-logo-spid" src="/img/spid-logo.svg"
                                                              onerror="this.onerror=null; this.src='img/spid-logo.png'"
                                                              alt="logo-spid"/>
      </div>
      <div class="ic1-col-md-12 ic1-col-12 ic1-centered ic1-no-up-padding" v-if="spidLevel">
        <div class="ic1-text-pretitle" style="color:white;">{{ spName }}</div>
      </div>
    </div>
    <!-- SPID LOGIN FORM * begin * -->
    <router-view
      @error="handleErrorEvent"
      @logged="handleMessageEvent('', $event)"
      @push="handleAbnormalClose"
      @parentOk="handleMessageEvent('', $event)"/>
    <!-- SPID LOGIN FORM * end * -->
  </div>

  <div v-else id="outer">
    <div id="contain-all">
      <div class="inner">
        <div class="grid spacer-top-1" v-if="spidLevel">
          <div class="width-one-whole spid-logo"><img :src=getImgUrl() alt="logo"/></div>
        </div>
        <div class="grid spacer-top-1" v-if="spName">
          <div class="width-one-whole pa-title">{{ spName }}</div>
        </div>
        <div class="grid spacer-top-1">
          <div class="width-one-whole"><span class="spid-hr"></span></div>
        </div>
        <div class="grid">
          <div class="width-one-whole">
            <!-- ERROR -->
            <div class="alert alert-error" role="alert" v-show="errored">
              <div class="alert-body">
                <h3 class="alert-heading" data-i18n="basicauth.label_error">
                  Errore!
                </h3>
                <p
                  class="alert-text"
                  data-i18n="[html]credential_state.INVALID"
                  data-i18n-options='{ "url": "" }'
                >
                  {{ errorCode }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- SPID LOGIN FORM * begin * -->
        <router-view
          @error="handleErrorEvent"
          @logged="handleMessageEvent('', $event)"
          @push="handleAbnormalClose"
          @parentOk="handleMessageEvent('', $event)"
        />
        <!-- SPID LOGIN FORM * end * -->

      </div>
    </div>
  </div>

  <div v-if="agid" class="ic1-footer-wrapper ">
    <div class="ic1-footer ">
      <div class="ic1-row">
        <div class="ic1-col-md-12 ic1-centered">
          <a href="http://www.spid.gov.it/check" target="blank_">
            <img
              id="spid-agid-logo"
              src="img/spid-agid-logo-bb.svg"
              onerror="this.src='img/spid-agid-logo-bb.png'; this.onerror=null;"
              alt="SPID - AgID Agenzia per l'Italia Digitale - Check"/>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- SPID FOOTER * begin * -->
  <div v-else id="footer">
    <div id="footer-inner">
      <div id="footer-custom">
        <div id="section1">TI Trust Technologies Srl con unico socio - C.F. P. IVA e Iscriz. Reg. Imp. Roma n.
          04599340967 - REA n. 1085826
        </div>
        <div id="section2">
          <ul id="footer-link">
            <li><a href="https://www.trusttechnologies.it/spid-contatti">Contatti</a></li>
            <li><a href="https://www.trusttechnologies.it/privacy">Privacy</a></li>
            <li><a href="https://www.trusttechnologies.it/chi-siamo">Chi Siamo</a></li>
            <li><a href="https://www.trusttechnologies.it/spid">SPID</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- SPID FOOTER * end * -->
</template>
<script>
import {useI18n} from "vue-i18n";
import AuthService from "./services/auth.service.js";
import {createCookie, getCookie, deleteCookie} from "@/utils/useCookie";
import {environment} from "@/config";

export default {
  name: 'App',
  props: ['application'],
  data() {
    return {
      value: 7,
      errored: false,
      retries: 5,
      info: null,
      transactionId: "",
      spidLevel: null,
      spName: null,
      profile: null,
      APP_NAME: null,
      APP_AUTHORIZATION_URL: null,
      classes: {
        "ic1-header-logo-new": true,
        "ic1-header-logo-custom": false,
      },
    }
  },
  setup() {
    console.log("setup method");
    // context has attrs, slots, and emit()
    const {t, locale} = useI18n()
    return {t, locale}

  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    logo() {
      return process.env.VUE_APP_SUB_PATH + "/img/idp-logo.png";
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    logo_fs() {
      return process.env.VUE_APP_SUB_PATH + "/img/logoSPID.png";
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    logo_sn() {
      return process.env.VUE_APP_SUB_PATH + "/img/Timid_Positivo_DEF.png";
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    logo_td() {
      return process.env.VUE_APP_SUB_PATH + "/img/logo_trust.png";
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    agid() {
      return process.env.VUE_APP_SUB_PATH != "tim";
    }
  },


  mounted() {
    this.spName = '';
    this.APP_NAME = environment.VUE_APP_NAME
    this.APP_AUTHORIZATION_URL = environment.VUE_APP_AUTHORIZATION_URL

    this.spidLevel = getCookie('spidLevel');
    this.spName = getCookie('spName');
    this.spProfile = getCookie('spProfile');

    if (this.spProfile) {
      this.setLogo(this.spProfile)
    }

    this.handleTransactionId();

    createCookie('locale', this.locale);
    createCookie('idp', environment.VUE_APP_IDP);
  },

  methods: {
    handleTransactionId() {
      this.transactionId = this.getParam('transactionId');

      if (this.transactionId) {
        console.log("transactionId from queryparam: " + this.transactionId);
        createCookie('transactionId', this.transactionId);
      }
    },

    setLogo(path) {
      if (path && path.length > 0) {
        this.$refs.logo.style.backgroundImage = "url('/" + path + "img/logo.png')"
        this.$refs.logo.style.backgroundSize = "contain";
        this.classes["ic1-header-logo-custom"] = true
        this.classes["ic1-header-logo-new"] = false
      }

    },
    getImgUrl() {
      return 'img/spid-level' + this.spidLevel + '-logo-bb.svg';

    },
    getParam(name) {
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get(name);
      return myParam;
    },

    handleErrorEvent(outputData) {
      console.log('Error:' + JSON.stringify(outputData.detail));
      const expr = outputData.status ?? null;
      const idToken = getCookie('id_token')
      const transactionIdBySessionStorage = getCookie('transactionId')

      if (expr === null || expr === 500 || expr === 410) {
        this.next(idToken, transactionIdBySessionStorage);
      }

      if (expr === 502 || expr === 503) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(this.APP_AUTHORIZATION_URL + '/fault?errorCode=2&source=o');
      }

      const errorMessage = outputData.message

      this.setErrorCode(errorMessage);
    },
    handleAbnormalClose(outputData) {
      console.log('handleAbnormalClose:' + outputData)
      const idToken = getCookie('id_token')
      this.transactionId = getCookie('transactionId')
      this.next(idToken, this.transactionId)
    },
    handleMessageEvent(inputData, outputData) {
      this.errored = false;
      console.log('spid-orch - handleMessageEvent:' + JSON.stringify(outputData.detail));
      const idToken = getCookie('id_token')

      const status = outputData.status
      const operation = outputData.operation
      console.log('status:' + status)
      console.log('operation:' + operation)

      console.log("event detail ok: " + inputData);
      this.transactionId = getCookie('transactionId');

      if (status === 200 && operation === "consent") {
        localStorage.clear();
        sessionStorage.clear();
      }

      setTimeout(this.next(idToken, this.transactionId), 1000);

    },
    next(idToken, transactionId) {
      console.log("calling next...")
      AuthService.next(idToken, transactionId).then(response => {
        console.log("App response request data:" + JSON.stringify(response));
        console.log("App response response data:" + response.data.step);
        return this.getRoutePath(transactionId, response, idToken)
      })
        .catch(error => {
          console.log("AuthService.next(idToken, transactionId) ERROR: " + JSON.stringify(error));
          /**
           500 internal server error
           400 bad request
           403 forbidden
           xxx
           */
          const expr = error.response.status;
          let errorMessage = null;
          localStorage.clear();
          sessionStorage.clear();
          switch (expr) {
            case 502: {
              window.location.replace(this.APP_AUTHORIZATION_URL + '/fault?errorCode=2&source=o');
            }
              break;
            default: {
              const locationHeader = error.response.headers['location'];
              console.log("attempts terminated response Location:" + locationHeader);
              if (locationHeader) {
                sessionStorage.clear();
                window.location.replace(locationHeader);
              } else {
                window.location.replace(this.APP_AUTHORIZATION_URL + '/fault?errorCode=3&source=o');
              }
            }
          }

          this.setErrorCode(errorMessage);
        })
        .finally(() => this.loading = false)
    },
    getRoutePath(transactionId, response, idToken) {
      const stepName = response.data.step
      let routePath = null;

      switch (stepName) {
        case 'otpSms':
          console.log('APP - otpSms: ' + response.data.data.attributes)
          this.$router.replace('/' + stepName)
          break;
        case 'authAttribute':
          console.log('APP - authAttribute')
          createCookie('attributesRequired', response.data.data.attributes)
          this.$router.replace('/' + stepName)
          break;
        case 'authParent':
          console.log('APP - authParent');
          if (response.data.data.impediment)
            createCookie('parentImpediment', response.data.data.impediment);
          else
            deleteCookie('parentImpediment');
          if (response.data.data.until)
            createCookie('parentUntil', response.data.data.until);
          else
            deleteCookie('parentUntil');
          this.$router.replace('/' + stepName);
          break;
        case 'end':
          console.log('APP - END')
          this.setCookie('Authorization', idToken, 2);
          window.location.href = response.data.data.redirectUri + "?transactionId=" + transactionId;
          break;
        case 'error':
          console.log('APP - END')
          this.setCookie('Authorization', idToken, 2);
          window.location.href = response.data.data.redirectUri + "?transactionId=" + transactionId;
          break;
        default:
          console.log('APP - DEFAULT')
        //?????????????

      }
    },
    setCookie(cname, cvalue, exsecs) {
      const d = new Date();
      d.setTime(d.getTime() + (exsecs * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None;Secure";
    },
    setErrorCode(errorMessage) {

      this.errorCode = this.t(errorMessage, {}, {locale: this.locale});

      this.errored = true
    },


  }

}
</script>

<style>
.ic1-header-logo-new {
  background-image: url("/img/logo-infocert-positivo-no-border.svg");
  background-repeat: no-repeat;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 2.5rem;
  width: 5.6rem;
}

.ic1-header-logo-custom {
  height: 2.25rem;
  width: 5rem;
  background-repeat: no-repeat;
}

.ic1-centered {
  display: flex;
  align-items: center;
  justify-content: center;

}

.ic1-card-error {
  /* Auto Layout */

  flex-direction: row;
  align-items: flex-start;
  padding: 0.625rem 1.75rem;
  position: static;
  width: fit-content;
  height: fit-content;
  left: 0px;
  top: 0px;
  flex-wrap: wrap;

  /* danger-alternative */

  background: #FBE7E7;
  /* danger */

  border: 1px solid #DA100B;
  box-sizing: border-box;
  border-radius: 5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.ic1-container {
  margin-top: 10px !important;
  flex: 2;
}

.ic1-bg-primary {
  background-color: #0066CC !important;
}

html, body {
  height: 100%;

}

html {
  position: relative;
}


.img-logo-spid {
  max-height: 30px !important;
}

html body {
  background-color: #0066CC;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}

.ic1-no-up-padding {
  padding-top: 0px !important;
}

.ic1-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*****  FOOTER  ******/
.ic1-footer-wrapper {
  position: static !important;
  bottom: 0;
  width: 100%;
  background: #333;
  padding-left: 0.521rem;
  padding-right: 0.521rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-bottom: 0px;
  margin-top: auto;
}


.ic1-footer-wrapper .ic-footer-info {
  font-size: 12px;
}

.ic1-footer-wrapper .ic-footer-link {
  text-align: right;
  font-size: 12px;
}

.ic1-footer .ic1-row {
  align-items: center !important;
}

.ic-footer-link a {
  margin-right: 14px;
}

.ic-footer-link a:last-child {
  margin-right: 0px;
}

@media screen and (min-width: 0) {
  .ic1-footer-wrapper {
    height: 40px
  }

  #spid-agid-logo {
    padding-top: 0px
  }

  #spid-agid-logo {
    height: 22px
  }
}

@media screen and (min-width: 750px) {
  .ic1-footer-wrapper {
    height: 55px
  }

  #spid-agid-logo {
    height: 37px
  }

  #spid-agid-logo {
    padding-top: 2px
  }
}

@media screen and (min-width: 1170px) {
  .ic1-footer-wrapper {
    height: 70px
  }

  #spid-agid-logo {
    padding-top: 5px
  }

  #spid-agid-logo {
    height: 50px
  }
}

/* end footer */


/**HEADER**/
:root {
  --header-z-index: 999;
  --header-inner-bg: #fff;
  --header-inner-height: 84;
}

.logo_spid {
  height: 30px;
}

.logo_tim {
  height: 30px;
}

.logo_trust {
  height: 22px;
}

#header {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: var(--header-z-index);
}

#header-inner {
  background-color: var(--header-inner-bg, #fff);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 0.5rem;
  align-items: center;
  padding: 15px;
}

#header-inner img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  -o-object-fit: contain;
  object-fit: contain;
  width: 200px;
}

@media screen and (min-width: 0) {
  #idp-logo {
    height: 40px;
  }
}

@media screen and (max-width: 610px) {
  #header-inner {
    flex-flow: column;
  }
}

@media screen and (min-width: 750px) {
  #idp-logo {
    height: 55px;
  }
}

@media screen and (min-width: 1170px) {
  #idp-logo {
    height: 40px;
  }
}
</style>
