import {createOidcAuth, LogLevel, SignInType} from 'vue-oidc-client/vue3';
import {createCookie, deleteCookie} from "@/utils/useCookie";
import {environment} from "@/config";

if((!window.navigator.cookieEnabled || !sessionStorage) && !window.location.pathname.includes('cookie')){
    window.location.replace("cookie.html")
}

console.log('APP: ' + `${environment.VUE_APP_AUTHORITY}`);
const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}/`
const authority = `${environment.VUE_APP_AUTHORITY}`;
const client_id = `${environment.VUE_APP_CLIENT_ID}`;
const client_secret = `${environment.VUE_APP_CLIENT_SECRET}`;
const urlParams = new URLSearchParams(window.location.search);
const transactionId = urlParams.get('transactionId');

const idsrvAuth = createOidcAuth(
    'main',
    SignInType.Window,

    appRootUrl,
    {

        authority: `${appRootUrl}${authority}`,
        client_id: `${client_id}`,
        client_secret: `${client_secret}`,
        response_type: 'code',
        scope: 'openid profile email api',
        redirect_uri: `${appRootUrl}callback`,
        prompt: 'login',
        loadUserInfo: false,
        extraQueryParams: {transactionId: transactionId},
        accessTokenExpiringNotificationTime: 10,
        clockSkew: 60 * 60 * 24 * 365
    },
    console,
    LogLevel.Debug
)

// handle events
idsrvAuth.events.addAccessTokenExpiring(function () {
    // eslint-disable-next-line no-console
    console.log('access token expiring')
})

idsrvAuth.events.addAccessTokenExpired(function () {
    // eslint-disable-next-line no-console
    console.log('access token expired')

    window.location.replace("/error?errorCode=11");

    //TODO: Eliminare tutti i cookie
    if(sessionStorage) sessionStorage.clear();
    if(localStorage) localStorage.clear();
})

idsrvAuth.events.addSilentRenewError(function (err: Error) {
    // eslint-disable-next-line no-console
    console.error('silent renew error', err)
})

idsrvAuth.events.addUserLoaded(function (user: any) {
    // eslint-disable-next-line no-console
    console.log('user loaded: '+ user.id_token);
    createCookie('id_token', user.id_token);
});

idsrvAuth.events.addUserUnloaded(function () {
    // eslint-disable-next-line no-console
    console.log('user unloaded')

})

idsrvAuth.events.addUserSignedOut(function () {
    // eslint-disable-next-line no-console
    console.log('user signed out')
})

idsrvAuth.events.addUserSessionChanged(function () {
    // eslint-disable-next-line no-console
    console.log('user session changed')
})


export default idsrvAuth
