import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import idsrvAuth from './idsrvAuth'
const MyMfa = () => import("./components/authenticators/MyMfa.vue")
const MyConsent = () => import("./components/MyConsent.vue")
const MyParent = () => import("./components/MyParent.vue")

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        meta: {
            authName: idsrvAuth.authName
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')

    },
    {
        path: "/otpSms",
        name: "smsauth",

        component: MyMfa,
    },
    {
        path: "/otpEmail",
        name: "otpEmail",

        component: MyMfa,
    },
    {
        path: "/mfa",
        name: "myMfa",

        component: MyMfa,
    },
    {
        path: "/authAttribute",
        name: "consent",

        component: MyConsent,
    },
    {
        path: "/authParent",
        name: "parent",

        component: MyParent,
    },
    {
        path: "/error",
        name: "errorPage",
        // lazy-loaded
        component: () => import(/* webpackChunkName: "error" */ './views/ErrorPage.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

idsrvAuth.useRouter(router)

export default router
